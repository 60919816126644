import $ from 'jquery';

import { $Elements, BrowserEvents, Classes, Elements } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(BrowserEvents.page.enter, () => {

	// ---------- Ajax Posts ----------

	if(typeof ajax_activities_params !== 'undefined') {

		$('form.ajax-activities-form').each(function() {

			const form = $(this);
			const button = form.find('#see-more-btn[type=submit]');
			const page = form.find('input[name=page]');
			const filters = form.find('.ajax-activities-filter');
			const content = form.find('.ajax-activities');
			const loader = form.find('.loader-ring');
			const noResults = form.find('.no-results');
			const lang = $Elements.html[0].lang;

			form.find('[name=date]').datepicker({
				container: form.find('.date-picker'),
				autoHide: true,
				language: (lang === 'fr' ? 'fr-FR' : 'en-EN'),
				format: (lang === 'fr' ? 'dd-mm-yyyy' : 'yyyy-mm-dd'),
				startDate: Date.now(),
			});

			button.on('click', () => form.data('append', true));

			filters.on('change', () => {
				form.data('append', false);
				page.val(1);
				// form.submit();
			});

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();
				console.log(form[0]);
				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const form_data = new FormData(form[0]);
				form_data.append('action', ajax_activities_params.action);
				form_data.append('nonce', ajax_activities_params.nonce);


				try {
					const response = await ajax({
						url: ajax_activities_params.admin_url,
						type: 'POST',
						dataType: 'json',
						data: form_data,
						processData: false,
						contentType: false,
					});

					if(response.data.content) {
						noResults.addClass(Classes.hidden);

						if(!form.data('append')) {
							content.empty();
						}

						content.append($(response.data.content));
						content.removeClass(Classes.hidden);
						loader.addClass(Classes.hidden);
					}
					else {
						content.addClass(Classes.hidden);
						noResults.removeClass(Classes.hidden);
					}

					if(response.data.next_page === 0) {
						button.addClass(Classes.hidden);
						button.attr('disabled', true);
					}
					else {
						page.val(response.data.next_page);
						button.removeClass(Classes.hidden);
					}
				}
				catch(e) {
					content.addClass(Classes.hidden);
					noResults.removeClass(Classes.hidden);
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});

				form.submit();

		});

		$('form#ajax-activities-form-home').each(function() {
			const form = $(this);
			const lang = $Elements.html[0].lang;

			form.find('[name=tc_date]').datepicker({
				container: form.find('.date-picker'),
				autoHide: true,
				language: (lang === 'fr' ? 'fr-FR' : 'en-EN'),
				format: (lang === 'fr' ? 'dd-mm-yyyy' : 'yyyy-mm-dd'),
				startDate: Date.now(),
			});
		})
	}
});
