import $ from 'jquery';

import { $Elements, BrowserEvents, Classes, Elements } from '../global/constants';
import { ajax } from '../global/helpers';

$Elements.document.on(BrowserEvents.page.enter, () => {

	// ---------- Ajax Posts ----------

	if(typeof ajax_posts_params !== 'undefined') {

		$('form.ajax-posts-form').each(function() {

			const form = $(this);
			const button = form.find('button[type=submit]');
			const page = form.find('input[name=page]');
			// const filters = form.find('.ajax-posts-filter');
			const content = form.find('.ajax-posts');
			const noResults = form.find('.no-results');

			button.on('click', () => form.data('append', true));

			// filters.on('change', () => {
			// 	form.data('append', false);
			// 	page.val(1);
			// 	form.submit();
			// });

			form.on('submit', async e => {
				e.preventDefault();
				e.stopImmediatePropagation();
				e.stopPropagation();

				button.attr('disabled', true);
				Elements.LoadingBar.classList.add(Classes.loading);

				const form_data = new FormData(form[0]);
				form_data.append('action', ajax_posts_params.action);
				form_data.append('nonce', ajax_posts_params.nonce);

				try {

					const response = await ajax({
						url: ajax_posts_params.admin_url,
						type: 'POST',
						dataType: 'json',
						data: form_data,
						processData: false,
						contentType: false,
					});

					if(response.data.content) {
						noResults.addClass(Classes.hidden);

						if(!form.data('append')) {
							content.empty();
						}

						content.append($(response.data.content));
						content.removeClass(Classes.hidden);
					}
					else {
						content.addClass(Classes.hidden);
						noResults.removeClass(Classes.hidden);
					}

					if(response.data.next_page === 0) {
						button.addClass(Classes.hidden);
						button.attr('disabled', true);
					}
					else {
						page.val(response.data.next_page);
						button.removeClass(Classes.hidden);
					}
				}
				catch(e) {
					content.addClass(Classes.hidden);
					noResults.removeClass(Classes.hidden);
				}

				button.attr('disabled', false);
				Elements.LoadingBar.classList.remove(Classes.loading);

				return false;
			});
		});
	}
});
