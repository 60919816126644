import $ from 'jquery';
import { Autoplay, EffectFade, Navigation, Pagination, Swiper } from 'swiper';

import { $Elements, BrowserEvents, TransitionDurations } from './constants';

$Elements.document.on(BrowserEvents.page.enter, () => {

	// ---------- Reviews Carousel ----------

	$('.reviews-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: TransitionDurations.default,
				slidesPerView: 1,
				spaceBetween: 20,
				breakpoints: {
					// when window width is <= 480px
					480: {
						slidesPerView: 1
					},
					// when window width is <= 768px
					768: {
						slidesPerView: 2
					},
					// when window width is <= 1024px
					1280: {
						slidesPerView: 3
					},
				},
				loop: false,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	// ---------- Partners Carousel ----------

	$('.partners-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: TransitionDurations.default,
				slidesPerView: 1.4,
				spaceBetween: 20,
				breakpoints: {
					// when window width is <= 480px
					480: {
						slidesPerView: 3
					},
					// when window width is <= 768px
					768: {
						slidesPerView: 6
					},
					// when window width is <= 1024px
					1280: {
						slidesPerView: 8
					},
				},
				loop: false,
				observer: true,
				modules: [Navigation, Pagination],
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});


	// ---------- Famous Tours Carousel ---------
	$('.slider-famous-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: TransitionDurations.animations,
				loop: true,
				observer: true,
				modules: [Pagination, EffectFade, Autoplay],
				autoplay: true,
				effect: 'fade',
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
				breakpoints: {
					1024: {
						loop: false,
					},
				},
			});
		}
	});

	// ---------- Slider Page Carousel ---------
	$('.page-slider-section .section-carousel').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: TransitionDurations.animations,
				loop: false,
				observer: true,
				modules: [Pagination, Autoplay],
				autoplay: true,
				slidesPerView: 1,
				spaceBetween: 24,
				centeredSlides: true,
				breakpoints: {
					480: {
						slidesPerView: 1
					},
					768: {
						slidesPerView: 2
					},
					1024: {
						slidesPerView: 3.7,
						centeredSlides: false,
						autoplay: false,
					},
					1440: {
						slidesPerView: 4,
						centeredSlides: false,
						autoplay: false,
					},
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
					clickable: true
				},
			});
		}
	});

	// ---------- Slideshow ----------

	$('.content-section .section-slideshow, .content-section .column-slideshow').each(function() {

		if(!this.swiper) {

			const swiper = new Swiper(this, {
				speed: TransitionDurations.default,
				loop: true,
				observer: true,
				autoplay: true,
				effect: 'fade',
				modules: [Autoplay, EffectFade, Navigation, Pagination],
				fadeEffect: {
					crossFade: true,
				},
				pagination: {
					el: this.querySelector('.swiper-pagination'),
				},
				navigation: {
					nextEl: this.querySelector('.swiper-button-next'),
					prevEl: this.querySelector('.swiper-button-prev'),
				},
			});
		}
	});

	$('.filter-type-activities').each(function() {
		const filterCheckboxes = document.querySelectorAll(".filters .filter-checkbox");
		const searchButton = document.querySelector(".search-submit");
		const urlParams = new URLSearchParams(window.location.search);
		const filterUrl = urlParams.get("filter");

		function applyFilters(selectedFilter) {
			document.querySelectorAll(".ajax-activities-wrapper .image-column, .activities-list-section .image-column").forEach(column => {
				if (selectedFilter === "all-tours" || selectedFilter === "") {
					column.classList.remove("d-none");
				} else {
					column.classList.toggle("d-none", !column.classList.contains(selectedFilter));
				}
			});
		}

		let defaultFilter = "all-tours";
		if (filterUrl) {
			defaultFilter = filterUrl;
		}

		filterCheckboxes.forEach(checkbox => {
			checkbox.checked = checkbox.getAttribute("data-activity") === defaultFilter;
		});

		function checkColumnsAndApplyFilters() {
			const checkColumns = setInterval(() => {
				const columns = document.querySelectorAll(".ajax-activities-wrapper .image-column, .activities-list-section .image-column");
				if(columns.length > 0) {
					clearInterval(checkColumns);
					applyFilters(defaultFilter);
				}
			}, 100);
		}

		checkColumnsAndApplyFilters();

		filterCheckboxes.forEach(checkbox => {
			checkbox.addEventListener("change", function() {
				filterCheckboxes.forEach(cb => cb.checked = false);
				this.checked = true;
				applyFilters(this.getAttribute("data-activity"));
			});
		});

		if (searchButton) {
			searchButton.addEventListener("click", () => {
				filterCheckboxes.forEach(checkbox => {
					checkbox.checked = checkbox.getAttribute("data-activity") === "all-tours";
				});
				applyFilters("all-tours");
			});
		}
	})
});
