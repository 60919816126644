import $ from 'jquery';

import { $Elements, BrowserEvents, Classes, Elements } from '../global/constants';
import { ajax } from '../global/helpers';


$Elements.document.on(BrowserEvents.page.enter, () => {

	// ---------- Handle Form ----------

	function handleForm(form, params) {

		const formDescription = $('.booking-simple-text-section');
		const formLegend = $('.form-legend');
		const formContent = form.find('.form-content');
		const formDone = form.find('.form-done');
		const formSuccess = formDone.find('.success');
		const formError = formDone.find('.error');
		const formButton = form.find('button[type=submit]');
		const lang = $Elements.html[0].lang;

		form.find('[name=date]').datepicker({
			container: form.find('.date-picker'),
			inline: true,
			autoPick: false,
			language: (lang === 'fr' ? 'fr-FR' : 'en-EN'),
			format: (lang === 'fr' ? 'dd-mm-yyyy' : 'yyyy-mm-dd'),
			startDate: Date.now(),
		});

		form.on('submit', e => e.preventDefault());

		form.parsley().on('form:submit', async function() {

			Elements.LoadingBar.classList.add(Classes.loading);
			formButton.attr('disabled', true);

			const formData = new FormData(form[0]);
			formData.append('action', params.action);
			formData.append('nonce', params.nonce);


			try {

				const response = await ajax({
					url: params.url,
					type: 'POST',
					dataType: 'json',
					data: formData,
					processData: false,
					contentType: false,
				});

				formSuccess.removeClass(Classes.hidden);
			}
			catch(e) {
				formError.removeClass(Classes.hidden);
			}

			Elements.LoadingBar.classList.remove(Classes.loading);
			formButton.attr('disabled', false);
			formContent.addClass(Classes.hidden);
			formLegend.addClass(Classes.hidden);
			formDescription.each(function() {
				$(this).addClass(Classes.hidden)
			});
			formDone.removeClass(Classes.hidden);

			$('body, html').animate({
				scrollTop: $('.content-wrapper').offset().top
			});

			return false;
		});
	}

	function handleWineClubForm(form, params) {

		const formTitle = $('.form-title');
		const formContent = form.find('.form-content');
		const formDone = form.find('.form-done');
		const formSuccess = formDone.find('.success');
		const formError = formDone.find('.error');
		const formButton = form.find('button[type=submit]');

		form.on('submit', e => e.preventDefault());

		form.parsley().on('form:submit', async function() {

			Elements.LoadingBar.classList.add(Classes.loading);
			formButton.attr('disabled', true);

			const formData = new FormData(form[0]);
			formData.append('action', params.action);
			formData.append('nonce', params.nonce);

			console.log(formData);

			try {

				const response = await ajax({
					url: params.url,
					type: 'POST',
					dataType: 'json',
					data: formData,
					processData: false,
					contentType: false,
				});

				formSuccess.removeClass(Classes.hidden);
			}
			catch(e) {
				formError.removeClass(Classes.hidden);
			}

			Elements.LoadingBar.classList.remove(Classes.loading);
			formButton.attr('disabled', false);
			formContent.addClass(Classes.hidden);
			formDone.removeClass(Classes.hidden);
			formTitle.addClass(Classes.hidden);

			$('body, html').animate({
				scrollTop: $('.wine-club-form-section').offset().top
			});

			return false;
		});
	}

	// ---------- Booking Form ----------

	if(typeof booking_form_params !== 'undefined') {

		$('#booking-modal').on('show.bs.modal', function(e) {
			const self = $(this);
			console.log('click');
			self.find('.form-content').removeClass('d-none');
			self.find('.form-done, .form-done .success, .form-done .error').addClass(Classes.hidden);
		});

		$('form#booking-tour-form').each(function() {
			handleForm($(this), booking_form_params);
		});
	}

	// ---------- Wine Club Form ----------

	if(typeof wine_club_form_params !== 'undefined') {

		$('form#wine-club-form').each(function() {
			handleWineClubForm($(this), wine_club_form_params);
		});
	}

});
