import $ from 'jquery';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import GLightbox from 'glightbox';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, BrowserEvents } from './constants';
import { fixMobileHover, sortAnimations, initFacebookPixel, initGTM, setCookiesAllowed, setDisclaimerAccepted, loadExternalScript } from './helpers';
import { calculateMenuOffset } from './menu';

// ---------- LazyLoad Config ----------

lazySizes.cfg.throttleDelay = 66;
lazySizes.cfg.preloadAfterLoad = true;

// ---------- Fix Scroll ----------

history.scrollRestoration = "manual";

// ---------- Regiondo ----------

$Elements.window.on('load', () => loadExternalScript('<script id="regiondo-js" src="https://cdn.regiondo.net/js/integration/bookingwidget/bookingwidget.js"></script>'));

// ---------- Fix Mobile Hover ----------

$Elements.window.on('load', () => fixMobileHover());

// ---------- jQuery Related ----------

$Elements.document.on(BrowserEvents.page.enter, () => {

	// ---------- Fix VH and VW----------
	$Elements.window.on('resize', () => {
		document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`);
		document.documentElement.style.setProperty('--vw', `${window.innerWidth}px`)
	})
	$Elements.window.trigger('resize');

	// ---------- Handle Aniamtions ----------

	ScrollTrigger.matchMedia(sortAnimations(Animations));

	// ---------- Links ----------

	$Elements.body.on('click', 'a[href="#"], .void-link', (e) => e.preventDefault());

	$Elements.body.on('click', '[data-scroll-to], [target="scroll-to"]', function(e) {
		e.preventDefault();

		const self = $(this);
		const target = (self.is('a')) ? $(self.attr('href')) : $(self.data('scroll-to'));

		if(target.length) {

			$('html, body').stop().animate({
				scrollTop: calculateMenuOffset(target)
			}, 1000);
		}
	});

	$Elements.body.on('click', '.select-dropdown .dropdown-menu .btn', function() {
		$(this).closest('.dropdown').find('.dropdown-toggle').html($(this).text());
	});

	// ---------- Button ScrollTo Content----------

	$('.scroll-to-content').click(function(e) {
		e.preventDefault();

		const totalHeight = $('section.hero-text-section').innerHeight() + 80;

		$('html, body').stop().animate({
			scrollTop: totalHeight,
		}, 1000);
	});

	// ---------- GLightbox ----------

	$('[target="lightbox"]').each(function() {

		GLightbox({
			selector: 'a[href="' + this.attributes.href.value + '"][target="lightbox"]',
			openEffect: 'fade',
			closeEffect: 'fade',
			videosWidth: 1280,
		});
	});

	$('[target^="lightbox-gallery"]').each(function() {

		GLightbox({
			selector: 'a[target="' + this.attributes.target.value + '"]',
			openEffect: 'fade',
			closeEffect: 'fade',
			videosWidth: 1280,
		});
	});

	// ---------- Disclaimer ----------

	$('#disclaimer').on('close.bs.alert', function() {
		setDisclaimerAccepted();
		$Elements.body.removeClass('disclaimer-open');
	});

	// ---------- Cookies ----------

	initGTM();

	$('#cookies-allowed').on('click', '[data-bs-dismiss="alert"]', function() {
		setCookiesAllowed(this.dataset.allowed);
	});


	// ---------- Form Range ----------

	$('.form-range').on('change', function() {
		const output = $(this).siblings('output');
		output.val(this.value);
	});

});
