import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { $Elements, Animations, BrowserEvents, Classes, Elements } from '../global/constants';

gsap.registerPlugin(ScrollTrigger);

// ---------- Init Scroll Animation ----------

export const initScrollAnimations = () => {

	// ---------- Animated Section ----------

	$('.animated-section:not(.transition-clone)').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					once: true,
					start: 'top 80%',
					toggleClass: Classes.active,
				}
			});
		});
	});

	// ---------- Content Sections ----------

	$('.parallax-section:not(.fixed-parallax-section):not(.transition-clone)').each(function() {

		Animations.mobile.min.push(() => {

			const image = this.querySelector(':scope .section-image');
			const speed = 25;

			if(image) {

				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: this,
						start: 'top bottom',
						end: 'bottom top',
						scrub: true,
					},
				});

				timeline.fromTo(image, { yPercent: -speed }, { yPercent: speed, ease: 'none' });
			}
		});
	});

	// ---------- Small Menu----------

	// $Elements.HeaderWrapper.each(function() {
	//
	// 	Animations.global.push(() => {
	//
	// 		const timeline = gsap.timeline({
	// 			scrollTrigger: {
	// 				trigger: Elements.PageWrapper,
	// 				start: this.offsetHeight / 2,
	// 				endTrigger: 'footer',
	// 				end: 'bottom top',
	// 				markers: true,
	// 				toggleClass: {
	// 					targets: this,
	// 					className: Classes.menuLight
	// 				},
	// 			}
	// 		});
	// 	});
	// });

	window.onscroll = function() {

		const header = $('#header-wrapper');

		if(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
			header.addClass(Classes.menuLight)
		}
		else {
			header.removeClass(Classes.menuLight)
		}
	};

	// ---------- CTAs Fade-in ----------

	$('.single .single-hero-section').each(function() {

		Animations.global.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: '50% 56%',
					endTrigger: 'footer',
					end: 'bottom top',
					// markers: true,
					toggleClass: {
						targets: $('.widget-column, .private-tour-cta-column'),
						className: Classes.show
					},
				}
			});
		});
	});

	// ---------- CTAs Fade-out-down Desktop ----------

	$('.single .reviews-section').each(function() {

		Animations.mobile.min.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: 'top bottom',
					endTrigger: 'footer',
					end: 'bottom top',
					// markers: true,
					toggleClass: {
						targets: $('.widget-column, .private-tour-cta-column'),
						className: Classes.fadeDown
					},
				}
			});
		});
	});


	// ---------- CTAs Fade-out Mobile ----------

	$('footer').each(function() {

		Animations.mobile.max.push(() => {

			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: this,
					start: '99% bottom',
					// markers: true,
					toggleClass: {
						targets: $('.widget-column, .private-tour-cta-column'),
						className: Classes.fadeDown
					},
				}
			});
		});
	});
};

$Elements.document.on(BrowserEvents.page.enter, () => initScrollAnimations());
